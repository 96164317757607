import React, { useMemo } from "react";
import { graphql } from "gatsby";
import replaceUrl from "replace-url-by-params";
import queryString from "query-string";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { isAndroid } from "react-device-detect";
import { format } from 'date-fns';

// Components
import {
  RichText,
  ShareButtons,
  Button,
  StoreStats,
  Footer,
  Asset,
} from "../../components";

// Constants
import { DONLOAD_STEPS } from "./constants";

// Styles
import "./styles.css";

const Page = ({ data, location }: any) => {
  const {
    title,
    subtitle,
    author,
    authorAvatar,
    ctaLink,
    buttonsColor,
    footerColor,
    showShareButtons,
    showStoreStats,
    showFooter,
    logo,
    appLogo,
    quotes,
    paragraphUnderQuotes,
    paragraphUnderFooter,
    paragraph1,
    paragraph2,
    paragraph3,
    asset1,
    asset2Ios,
    asset2android
  } = data.contentfulOutbrainPage;

  const logoImage: any = getImage(logo.localFile);
  const authorAvatarImage: any = getImage(authorAvatar.localFile);

  const {
    campaign: campaign_name,
    utm_source: network_name,
    adgroup: adgroup_name,
    outbrain,
  }: any = queryString.parse(location.search);
  const finalCtaLink = useMemo(
    () =>
      replaceUrl(ctaLink, {
        network_name,
        campaign_name,
        adgroup_name,
      }) || "",
    [ctaLink, network_name, campaign_name, adgroup_name, replaceUrl]
  );

  if (finalCtaLink === "") {
    return (
      <div className="w-screen h-screen flex justify-center items-center font-extralight text-2xl">
        Loading...
      </div>
    );
  }

  return (
    <main className="outbrain-page">
      <article>
        <div className={"flex flex-col gap-3 px-8 pt-3 bg-[#F5FAF1] pb-6"}>
          <div className="text-[8px] text-center font-[600] text-gray-400">
            ADVERTORIAL
          </div>
          <section className="flex justify-between items-center">
            <div>
              <GatsbyImage image={logoImage} alt={logo.title} />
            </div>
            <div>
              <Button
                title="DOWNLOAD APP"
                url={finalCtaLink}
                color={buttonsColor}
              />
            </div>
          </section>
        </div>
        <hr className={"my-0 h-2 bg-[#D5EBC4]"} />

        <section className="container m-auto px-8 py-3 flex flex-col gap-3">
          <div className="flex flex-col gap-3">
            <h1 className="text-[32px] text-black font-semibold">{title}</h1>
            <div
              className={"text-[10px] text-[#262323] flex gap-2 items-center"}
            >
              {!!authorAvatar && (
                <GatsbyImage
                  className="rounded-[50px] w-[40px] h-[40px]"
                  image={authorAvatarImage}
                  alt={logo.title}
                />
              )}
              <h2>{subtitle.replace('{date}', format(new Date(), 'MMMM d, yyyy'))}</h2>
              <h3 className={"font-bold text-blue-600"}>{author}</h3>
            </div>
          </div>

          <section>
            <RichText
              isOutbrain={true}
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
              contentfullText={paragraph1}
            />
          </section>
          {showShareButtons && <ShareButtons color={buttonsColor} />}

          <div className="flex flex-col gap-2 justify-center items-center">
            <Asset data={asset1} />
            <div className="font-bold">
              Social Casino Slots Go Viral – Legally and For Free!
            </div>
          </div>

          <section>
            <RichText
              isOutbrain={true}
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
              contentfullText={paragraph2}
            />
          </section>
          <section className="quotes flex flex-col gap-6 px-8 pt-8">
            <RichText
              isOutbrain={true}
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
              contentfullText={quotes}
            />
          </section>

          {!!paragraphUnderQuotes && (
            <section>
              <RichText
                isOutbrain={true}
                ctaLink={finalCtaLink}
                buttonsColor={buttonsColor}
                contentfullText={paragraphUnderQuotes}
              />
            </section>
          )}
          <hr />

          <div className="flex justify-center items-center">
            <Asset data={isAndroid ? asset2android : asset2Ios} />
          </div>

          {showStoreStats && (
            <StoreStats
              appLogo={appLogo}
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
            />
          )}
          <section></section>

          <hr />

          <section>
            <RichText
              isOutbrain={true}
              contentfullText={paragraph3}
              ctaLink={finalCtaLink}
              buttonsColor={buttonsColor}
            />
          </section>
          <div className="py-6 px-8 bg-[#F5FAF1] rounded-[15px] border-2 border-[#D5EBC4] italic">
            <p className="text-[15px] text-black font-bold text-center mb-2">
              Here is what you do:
            </p>
            {DONLOAD_STEPS.map((step, index) => (
              <p className="flex text-[15px] text-black font-semi gap-1">
                <span className="text-[15px] text-black font-semi">
                  {index + 1}.
                </span>
                {step}
              </p>
            ))}
          </div>
          <div className="button-wrapper text-center my-4">
            <Button
              title="DOWNLOAD the Quick Hit Slots, COLLECT 6,000,000 FREE coins, and SPIN  >>>"
              url={finalCtaLink}
              color={"#EE0808"}
              fontSize={14}
              maxWidth={"230px"}
            />
          </div>
        </section>
      </article>

      {showFooter && <Footer ctaLink={finalCtaLink} color={footerColor} />}
      {!!paragraphUnderFooter && (
        <section className="paragraph-under-footer mt-2 mb-10 px-10">
          <RichText
            isOutbrain={true}
            ctaLink={finalCtaLink}
            buttonsColor={buttonsColor}
            contentfullText={paragraphUnderFooter}
          />
        </section>
      )}
    </main>
  );
};

export const Head = ({ data }: any) => {
  return (
    <>
      <title>{data.contentfulOutbrainPage.title}</title>
      {data.contentfulOutbrainPage.pixels && (
        <script>{data.contentfulOutbrainPage.pixels.pixels}</script>
      )}
    </>
  );
};

export const data = graphql`
  query outbrainPage($slug: String) {
    contentfulOutbrainPage(slug: { eq: $slug }) {
      title
      subtitle
      author
      authorAvatar {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 68)
          }
        }
      }
      ctaLink
      buttonsColor
      footerColor
      showShareButtons
      showStoreStats
      showFooter
      logo {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 68)
          }
        }
      }
      appLogo {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 79)
          }
        }
      }
      asset1 {
        title
        publicUrl
        file {
          contentType
        }
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1000)
          }
        }
      }
      asset2Ios {
        title
        publicUrl
        file {
          contentType
        }
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1000)
          }
        }
      }
      asset2android {
        title
        publicUrl
        file {
          contentType
        }
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1000)
          }
        }
      }
      quotes {
        raw
      }
      paragraphUnderQuotes {
        raw
      }
      paragraphUnderFooter {
        raw
      }
      paragraph1 {
        raw
      }
      paragraph2 {
        raw
      }
      paragraph3 {
        raw
      }
      pixels {
        pixels
      }
    }
  }
`;

export default Page;
